import axios from 'axios';

function Blackhole(contentType) {
    return axios.create({
        baseURL: process.env.VUE_APP_BLACKHOLE,
        withCredentials: false,
        headers: {
            'Content-Type': contentType,
            'Authorization': 'JWT ' + localStorage.mtopToken
        }
    });
}

export default {
    createObject(formData) {
        return new Blackhole('multipart/form-data').post("/object/create", formData)
    },
    readImage(id) {
        return process.env.VUE_APP_BLACKHOLE + '/object/retrieve/' + id + '?tokenId=' + localStorage.mtopToken;
    }
}